<template>
    <div class="component-select">
        <select v-model="comp.selected" @change="comp.update">
            <option value="">선택</option>
            <option :value="irow.idx" v-for="(irow, i) in comp.list" :key="i">{{ irow.kname }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'

export default {
    name: 'CarrotCompanyStep',
    props: {
        modelValue: {
            type: String,
            default: "",
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const comp = reactive({
            selected : "",
            list     : [],

            doSearch: () => {
                comp.list = [
                   /* { idx : "0", kname : "0단계(Quit고객)" },
                    { idx : "1", kname : "1단계(현재고객사)" },*/
                    { idx : "2", kname : "2단계(미팅고객사)" },
                    { idx : "3", kname : "3단계(일반고객사)" },
                    { idx : "9", kname : "9단계(지원제휴사)" },
                ];
            },

            update: () => {
                emit('update:modelValue', comp.selected);
            }

        });

        onMounted(() => {
            // Mounted
            comp.selected = props.modelValue;

            comp.doSearch();
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .component-select {
        display: inline-block;
        select {
            width: 100%;
        }
    }
</style>